import React from 'react'
import { Box, Spinner, Heading } from '@chakra-ui/react'

type Props = {
  size?: string
  text?: string
  hideText?: boolean
}

const Loader = ({ size, text }: Props) => {
  return (
    <Box w="full" textAlign="center" p={12}>
      <Spinner my={4} size={size ?? 'xl'} />
      <Heading fontSize="xl">{text ?? 'Loading'}</Heading>
    </Box>
  )
}

export default Loader
